<template>
  <div>
    <h2>Help</h2>
  </div>
</template>

<script>
export default {
  name: 'Help',
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/help';
</style>
